// utils/axios.js
import axios from 'axios';
import { useJobId } from '../contexts/ContextJbo';
import { useContext } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { BASE_URL } from './config';
import * as Sentry from "@sentry/react";


/**
 * Custom hook to create an Axios instance with specific configurations and interceptors.
 * It leverages contexts for job ID and currency to enrich each request with additional data.
 *
 * @returns {AxiosInstance} - The configured Axios instance.
 */
export function useAxios() {
     // Accessing job ID and currency from their respective contexts.
     const { jboId } = useJobId();
     const theme = useContext(ThemeContext);
    //console.log("jboIdAxiosconsole",jboId);
    const { userId, token } = useContext(UserContext);
    const { currentCurrency } = useContext(CurrencyContext); // new line
  
    // Creating an Axios instance with a predefined base URL and headers.
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        // add other headers as needed
      },
      timeout: 60000, // Timeout in milliseconds (60s) - 04-09-2024
  
    });
  
  // Interceptor to modify every outgoing request.
    instance.interceptors.request.use((config) => {
   // Ensuring the request has a 'data' property and it's an object.
      // Adding job ID and currency information to the request's payload.
      // const adjustedUserId = theme.siteStatus === 0 ? '0' : (userId ? userId : null);
      config.data = {
        data: JSON.stringify({
          ...config.data,
          client_id:jboId,
          jbo_id: jboId,
          user_id: userId,
          currency: currentCurrency, 
        }),
      };
      return config;
    }, (error) => {
      // Do something with request error
      Sentry.captureException(error);
      return Promise.reject(error);
    });
  


  return instance;
}