import React, { useContext, useState, useEffect } from "react";
import Fab from '@mui/material/Fab';
import { UserContext } from '../../contexts/UserContext';
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useJobId } from '../../contexts/ContextJbo';

const ChatIcon = () => {
    const { featureCustomizations } = useContext(UserContext);
    const [chatLink, setChatLink] = useState('');
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const { isMobile, isTablet } = useDeviceSize();
    const { jboId } = useJobId();

    useEffect(() => {
        if (featureCustomizations.chat_link) {
            setChatLink(featureCustomizations.chat_link);
        }
    }, [featureCustomizations]);

  


    

    // animation for chat icon bounce
    const checkForElement = () => {
        const interval = setInterval(() => {
            const imgElement = document.getElementById('engtLauncherIcon');
            if (imgElement) {
                clearInterval(interval);
                applyBounceAnimation();
            }
        }, 3000);
    };

    const applyBounceAnimation = () => {
        const styleSheet = document.createElement('style');
        styleSheet.type = 'text/css';
        styleSheet.innerText = `
        @keyframes bounceImage {
            0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
            }
            40% {
            transform: translateY(-10px);
            }
            60% {
            transform: translateY(-5px);
            }
        }
        .bounce-animation {
            animation: bounceImage 2s infinite !important;
        }`;
        document.head.appendChild(styleSheet);

        const imgElement = document.getElementById('engtLauncherIcon');
        imgElement?.classList.add('bounce-animation');
    };


    const toggleLiveChat = () => {
        if (jboId !== 233 || !scriptLoaded) return; // Ensure toggle only works when jboId is 233 and script is loaded

        // // Here you would add the logic to open the live chat widget if it has a specific method or event
        // // For example:
        // if (window.EngtChat) {
        //     window.EngtChat.open();
        // }

        // Assuming there's an API to close the chat widget or if you have a way to determine when it closes
        // For example, if it closes after 5 minutes:
        setTimeout(() => {
            document.head.removeChild(document.querySelector('script[src*="widget.js"]'));
            setScriptLoaded(false); // Script has been removed
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
    };

    if (jboId === 233) {
        return null; // Do not render the chat icon if jboId is 233
    }
    console.log("chatLink",chatLink);
    return (
        <>
            {
               
                    <>
                      {/* // only render for webview - 19/09/2024 */}
                    {chatLink  && <div className="hidden lg:block">
                        <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '20px', width: '50px', height: '50px' }}>
                        <a href={chatLink} className="hover:text-primary"
                            onClick={(e) => {
                                if (!chatLink) {
                                    e.preventDefault();
                                } else {
                                    toggleLiveChat();
                                }
                            }}
                        >
                            <i className="fas fa-comments text-xl lg:text-1xl"></i>
                        </a>
                    </Fab>
                    </div> }
                    
                    </>
                   
            }
        </>
    )
};

export default ChatIcon;
