import React, { useState, useEffect, useContext, useRef } from "react";
import Link from "../../components/custom/Link";
import {
  FaExclamationCircle,
  FaWhatsapp,
  FaTimes,
  FaUser,
} from "react-icons/fa";
import { useRouter } from "next/router";
import Modal from "react-modal";
import { ThemeContext } from "../../contexts/ThemeContext";
import { UserContext } from "../../contexts/UserContext";
import MobileSearchBar from "../Search/MobileSearchBar";

import { GoHomeFill } from "react-icons/go";
import { RiVipCrown2Fill } from "react-icons/ri";

Modal.setAppElement("#__next");

const customStyles = {
  content: {
    top: "auto",
    left: "auto",
    right: "20px",
    bottom: "80px",
    width: "300px",
    borderRadius: "8px",
    border: "none",
    padding: "0",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

const MobileMenu = () => {
  const { userId, token, menuJsonApp, thumbnailStatus } =
    useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [GoldPlan, setGoldPlan] = useState(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const theme = useContext(ThemeContext);
  const [error, setError] = useState(null);
  const [searchEnable, setSearchEnable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const router = useRouter();
  const catMenuRef = useRef(null);
  const currentPath = router.pathname;
  const { slug } = router.query;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        catMenuRef.current &&
        !catMenuRef.current.contains(event.target) &&
        !event.target.closest(".fa-bars")
      ) {
        var menu = document.getElementById("catMenu");
        menu?.classList.add("hidden");
      }
    };

    // check gold plan status is already updated or not in localstorage
    const goldPlanStatus = localStorage.getItem("goldPlanStatus");
    // if gold plan status is not stored then
    // store the gold plan status
    console.log("menu json status:", menuJsonApp);
    console.log(
      "saving scheme enable status : ",
      menuJsonApp.savings_scheme_enable
    );
    console.log("gold plan status : ", goldPlanStatus);
    if (!goldPlanStatus) {
      console.log(
        "status : ",
        menuJsonApp.savings_scheme_enable == 1 ? "1" : "0"
      );

      localStorage.setItem(
        "goldPlanStatus",
        menuJsonApp.savings_scheme_enable == 1 ? "1" : "0"
      );
    } else {
      // check status for gold plan status updates
      let enableStatus = menuJsonApp.savings_scheme_enable;

      if (enableStatus) {
        enableStatus = enableStatus == 1 ? "1" : "0";
        if (enableStatus !== goldPlanStatus) {
          localStorage.setItem(
            "goldPlanStatus",
            menuJsonApp.savings_scheme_enable == 1 ? "1" : "0"
          );
        }
      }
    }

    setGoldPlan(goldPlanStatus);

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [menuJsonApp]);
  useEffect(() => {
    // Update the state if the current path includes 'search'
    if (
      currentPath.includes("search") ||
      (!slug?.includes("buy") && slug?.length === 3)
    ) {
      setSearchEnable(true);
    } else {
      setSearchEnable(false);
    }
  }, [currentPath, slug, router.query]);
  useEffect(() => {
    if (menuJsonApp && menuJsonApp.menu_list) {
      setCategoriesData(menuJsonApp.menu_list);
      // if (menuJsonApp.savings_scheme_enable === 1) {
      //   // if (!token && userId != "null" && !userId) {
      //   //   // setGoldPlan(true);
      //   // } else {

      //   // }
      //   setGoldPlan(true);
      // } else {
      //   setGoldPlan(false);
      // }
      setIsLoading(false);
    }
  }, [menuJsonApp]);

  const handleSearch = (e) => {
    e.preventDefault();
    router.push(`/search/${searchValue}`);
    setSearchValue("");
  };
  // handle search click for modal open - 13-08-2024
  const handleSearchClick = () => {
    setIsSearchModalOpen(true);
  };
  // handle close func for modal close - 13-08-2024
  const handleCloseSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const handleAuth = () => {
    const url = window.location.href;
    document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;

    router.push("/auth/SignIn");
  };
  const hrefAttribute = theme.siteStatus === 0 ? `/?pid=${theme.token}` : "/";

  const menuClick = () => {
    var menu = document.getElementById("mobileMenu");
    menu?.classList.toggle("hidden");
  };

  const closeMenu = () => {
    var menu = document.getElementById("mobileMenu");
    menu?.classList.add("hidden");
  };

  const catCloseMenu = () => {
    var menu = document.getElementById("catMenu");
    menu?.classList.add("hidden");
    document.body.style.overflow = "auto";
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
  };

  const DropdownMenu = ({ category }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <li>
        <div
          className="flex justify-between items-center cursor-pointer px-6 py-2 hover:bg-gray-100 transition"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h1 className="text-lg font-semibold text-primary uppercase">
            {category.name}
          </h1>
          <i className={`fas fa-chevron-${isOpen ? "up" : "down"}`}></i>
        </div>
        {isOpen && (
          <ul className="pl-4">
            {category.child.map((sub) => (
              <SubDropdownMenu sub={sub} key={sub.id} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  const SubDropdownMenu = ({ sub }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <li className="ml-4">
        {sub.status === 1 ? (
          <a
            href={`${(sub.id && sub.url) || ""}`}
            className="flex justify-between items-center cursor-pointer px-6 py-2 hover:bg-gray-100 transition"
          >
            <h1 className="text-lg font-semibold text-primary uppercase">
              {sub.name}
            </h1>
            <span
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
              className="cursor-pointer"
            >
              <i className={`fas fa-chevron-${isOpen ? "up" : "down"}`}></i>
            </span>
          </a>
        ) : (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="flex justify-between items-center cursor-pointer px-6 py-2 hover:bg-gray-100 transition"
          >
            <h1 className="text-lg font-semibold text-primary uppercase">
              {sub.name}
            </h1>
            <span className="cursor-pointer">
              <i className={`fas fa-chevron-${isOpen ? "up" : "down"}`}></i>
            </span>
          </div>
        )}

        {isOpen && (
          <ul className="pl-4">
            {sub.child?.map((child) => (
              <li key={child.id}>
                <a
                  href={child.url}
                  className="block px-6 py-2 capitalize text-primary hover:bg-gray-100 transition cursor-pointer ml-12"
                >
                  {child.name}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <>
      <div className="fixed w-full border-t border-gray-200 shadow-sm bg-secondary py-1 bottom-0 left-0 flex justify-around items-start lg:hidden z-40">
        <Link
          href="#"
          onClick={handleLinkClick}
          className="block text-center text-primary hover:text-alternative transition relative"
        >
          <div className="text-2xl">
            <i className="fas fa-bars" onClick={menuClick}></i>
          </div>
          <div className="text-xs leading-3 font-semibold">Menu</div>
        </Link>
        {/* rendering search modal - 13-08-2024 */}
        {searchEnable && (
          <Link
            href="#"
            className="block text-center text-primary hover:text-alternative transition relative"
            onClick={handleSearchClick}
          >
            <div className="text-2xl">
              <i className="fas fa-search"></i>
            </div>
            <div className="text-xs leading-3 font-semibold">Search</div>
          </Link>
        )}

        <Link
          href={hrefAttribute}
          className="block text-center text-primary hover:text-alternative transition relative"
        >
          <div className="text-2xl flex justify-center">
            <GoHomeFill size={30} />
          </div>
          <div className="text-xs leading-3 font-semibold">Home</div>
        </Link>

        {GoldPlan === "1" ? (
          <Link
            href={`/GoldPlan/PlanList`}
            className="block text-center text-primary hover:text-alternative transition relative"
          >
            <div className="text-2xl flex justify-center">
              <RiVipCrown2Fill size={30} />
            </div>
            <div className="text-xs leading-3 font-semibold">Gold Plan</div>
          </Link>
        ) : (
          <Link
            href={`/profile/Wishlist`}
            className="block text-center text-primary hover:text-alternative transition relative"
          >
            <div className="text-2xl">
              <i className="fas fa-heart"></i>
            </div>
            <div className="text-xs leading-3 font-semibold">Wishlist</div>
          </Link>
        )}
        {theme.siteStatus !== 0 &&
          (token && userId !== "null" && userId ? (
            <Link
              href="/profile/User"
              className="text-center text-primary hover:text-alternative transition relative"
            >
              <div className="text-2xl">
                <i className="fas fa-user"></i>
              </div>
              <div className="text-xs leading-3 font-semibold">Account</div>
            </Link>
          ) : (
            <span
              onClick={handleAuth}
              className="text-center text-primary hover:text-alternative transition relative"
            >
              <div className="text-2xl">
                <i className="fas fa-user"></i>
              </div>
              <div className="text-xs leading-3 font-semibold">Login</div>
            </span>
          ))}
        {/* <div
          onClick={() => setModalIsOpen(true)}
          className="fixed bottom-20 right-4 bg-green-500 text-white p-2 rounded-full cursor-pointer"
        >
          <FaWhatsapp size={32} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={customStyles}
          contentLabel="WhatsApp Modal"
        >
          <div className="flex flex-col h-full bg-white rounded-t">
            <div className="p-4 bg-primary text-white rounded-t flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <h2 className="text-lg">JPencil Online Shopping</h2>
              </div>
              <FaTimes onClick={() => setModalIsOpen(false)} className="cursor-pointer" />
            </div>
            <div className="flex-grow p-4 space-y-2">
              <div className="chat-message flex">
                <div className="mr-3">
                  <div className="bg-primary rounded-full text-white flex items-center justify-center" style={{ width: "32px", height: "32px" }}>
                    <FaUser />
                  </div>
                </div>
                <div className="bg-white text-black rounded-lg px-3 py-2">
                  <p className="text-gray-600">JPencil</p>
                  <p className="text-sm">Hi there!</p>
                </div>
              </div>
            </div>
            <div className="p-4 bg-gray-100">
              <Link
                href="https://wa.me/919876543210"
                className="bg-primary text-white p-2 rounded block text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat with us
              </Link>
            </div>
          </div>
        </Modal> */}
      </div>
      <div
        className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-30 shadow hidden"
        id="mobileMenu"
      >
        <div className="absolute left-0 top-0 w-full h-full z-50 bg-white shadow">
          {/* ui modification close menu - 21-08-2024 */}
          <div
            className="text-white bg-transparent font-bold hover:text-primary text-lg absolute right-3 top-4 cursor-pointer"
            id="closeMenu"
            onClick={closeMenu}
          >
            <i className="fas fa-times"></i>
          </div>
          <h3 className="text-xl font-semibold text-white mb-2 pl-4 pt-4 pb-4 bg-primary">
            Menu
          </h3>
          <ul className="overflow-y-auto h-full max-h-screen">
            <li>
              <a
                href={hrefAttribute}
                className="px-6 py-3 flex items-center hover:bg-gray-100 transition"
              >
                <i className="fas fa-home text-primary text-lg mr-4"></i>
                <span className="text-primary text-sm font-semibold">Home</span>
              </a>
            </li>
            <li>
              <a
                href="/AboutUs"
                className="px-6 py-3 flex items-center hover:bg-gray-100 transition"
              >
                <i className="fas fa-info-circle text-primary text-lg mr-4"></i>
                <span className="text-primary text-sm font-semibold">
                  About Us
                </span>
              </a>
            </li>
            <li>
              <a
                href="/ContactUs"
                className="px-6 py-3 flex items-center hover:bg-gray-100 transition"
              >
                <i className="fas fa-envelope text-primary text-lg mr-4"></i>
                <span className="text-primary text-sm font-semibold">
                  Contact Us
                </span>
              </a>
            </li>
            <li>
              <a
                href="/profile/Wishlist"
                className="px-6 py-3 flex items-center hover:bg-gray-100 transition"
              >
                <i className="fas fa-heart text-primary text-lg mr-4"></i>
                <span className="text-primary text-sm font-semibold">
                  Wishlist
                </span>
              </a>
            </li>
          {/* removed chat & phone - 19/09/2024 */}
          </ul>
        </div>
      </div>
      <div
        className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-30 shadow hidden"
        ref={catMenuRef}
        id="catMenu"
      >
        <h2 className="font-bold text-xl text-primary px-6 py-4 border-b border-gray-200 z-50 bg-white">
          Categories
        </h2>
        <div
          className="text-alternative font-bold hover:text-primary text-lg absolute right-3 top-4 cursor-pointer"
          onClick={catCloseMenu}
        >
          <i className="fas fa-times"></i>
        </div>
        <div className="absolute left-0 w-full h-full z-50 bg-white shadow overflow-auto">
          <ul className="overflow-y-auto h-full max-h-[120vh] pb-16">
            {" "}
            {/* Added padding-bottom for the last item */}
            {error ? (
              <li>
                <div className="bg-primary mt-5 text-white rounded-lg p-4 shadow-lg">
                  <div className="flex items-center">
                    <FaExclamationCircle className="text-2xl mr-4" />
                    <span className="font-bold">An error has occurred:</span>
                  </div>
                  <p className="mt-2">{error}</p>
                </div>
              </li>
            ) : (
              categoriesData?.map((category) => (
                <DropdownMenu category={category} key={category.id} />
              ))
            )}
          </ul>
        </div>
      </div>
      {/* rendering modal open - 13/08/2024 */}
      {isSearchModalOpen && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-50"
          onClick={handleCloseSearchModal}
        >
          <div
            className="relative bg-white shadow-lg w-full max-w-md mx-auto"
            onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
          >
            <MobileSearchBar setClose={setIsSearchModalOpen} />
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
