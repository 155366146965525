import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ThemeContext } from "../../contexts/ThemeContext";
import { UserContext } from '../../contexts/UserContext';

function MobileSearchBar({ setClose = () => false }) {
  const [searchValue, setSearchValue] = useState(''); // State to manage the search input value
  const { featureCustomizations, metalCategories } = useContext(UserContext); // Access user-related data from context
  const [searchKey, setSearchKey] = useState(['1', '2', '3']); // State to store search keys (placeholder values)
  const [currentProductIndex, setCurrentProductIndex] = useState(0); // State to track the current placeholder index
  const [isPlaceholderReady, setIsPlaceholderReady] = useState(false); // State to handle when to show the placeholder
  const router = useRouter(); // Next.js router for navigation
  const theme = useContext(ThemeContext); // Accessing the theme context

  // Handle the search functionality
  const handleSearch = (e) => {
    e.preventDefault();
    const searchUrl = theme.siteStatus === 0 ? `/search/${searchValue}?pid=${theme.token}` : `/search/${searchValue}`;
     // redirection updated
     window.location.href=searchUrl
    setSearchValue(''); // Clear the input field
    setClose(false); // Close the search bar if required
  };

  // Populate searchKey based on featureCustomizations or metalCategories
  useEffect(() => {
    if (featureCustomizations && featureCustomizations.is_search_key_enabled == 1 && featureCustomizations.search_key) {
      setSearchKey(featureCustomizations.search_key);
    } else {
      getCategoryNames(metalCategories);
    }
  }, [featureCustomizations, metalCategories]);

  // Extract names from metalCategories to populate searchKey
  const getCategoryNames = (names) => {
    var totalItems = [];
    if (!Array.isArray(names)) {
      console.error('Names is not an array');
      return;
    }
    names.forEach(item => {
      totalItems.push(item.name);
      if (item.child) {
        item.child.forEach(subItem => {
          totalItems.push(subItem.name);
          if (subItem.child) {
            subItem.child.forEach(innerItem => {
              totalItems.push(innerItem.name);
            });
          }
        });
      }
    });
    setSearchKey(totalItems);
  };

  // Randomly cycle through searchKey items for placeholder
  const cycleProducts = () => {
    setCurrentProductIndex(Math.floor(Math.random() * searchKey.length));
  };

  // Set an interval to cycle through products every 2 seconds
  useEffect(() => {
    const interval = setInterval(cycleProducts, 2000);
    return () => clearInterval(interval);
  }, [searchKey]);

  // Delay rendering of the placeholder by 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaceholderReady(true);
    }, 3000); // 3 seconds delay
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <div className="bg-white shadow-sm flex items-center">
      <div className="w-full mx-auto p-2">
        <div className="relative flex items-center">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-lg text-primary">
              <i className="fas fa-search"></i>
            </span>
            <input
              id="search_field"
              name="search_field"
              className="block w-full h-10 pl-12 pr-3 py-2 border border-r-0 border-primary bg-white text-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary min-h-[40px]" // Prevent layout shift
              placeholder={
                isPlaceholderReady && searchKey[currentProductIndex]
                  ? `Search for ${searchKey[currentProductIndex]}`
                  : 'Search'
              }
              type="search"
              value={searchValue} // Bind the input value to state
              onChange={(e) => setSearchValue(e.target.value)} // Update state on input change
            />
            <button
              type="submit"
              onClick={handleSearch} // Trigger search on button click
              className="bg-primary border border-primary text-white px-8 font-medium hover:bg-transparent hover:text-primary transition"
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MobileSearchBar;
