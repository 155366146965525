import React, { useContext, useState, useEffect } from "react";

import { UserContext } from '../../contexts/UserContext';

import { useJobId } from '../../contexts/ContextJbo';
const PhoneIconTwo = () => {
    const { featureCustomizations } = useContext(UserContext);
    const [phoneNo, setPhoneNo] = useState('');
  
    const { jboId } = useJobId();
    useEffect(() => {
        if (featureCustomizations.mobile_number) {
            setPhoneNo(featureCustomizations.mobile_number);
        }
    }, [featureCustomizations]);

    const mobileStyle = {
        position: 'fixed',
        bottom: '60px',
        right: '80px',
        zIndex: '9999',
        width: '40px',
        height: '40px'
    };

    const tabletStyle = {
        position: 'fixed',
        bottom: '50px',
        right: '65px',
        zIndex: '9999',
        width: '40px',
        height: '40px'
    };

    const defaultStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '80px',
        zIndex: '9999',
        width: '50px',
        height: '50px'
    };

    

    // If jboId is 369, do not render the phone icon
    if (jboId === 369 || !phoneNo) {
        return null;
    }

    return (
      
       
        <>
       <div class="fixed bottom-[50px] lg:bottom-[20px] right-[10px] z-[9999] w-12 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center">
    <a
        href={`tel:${phoneNo}`}
        className="p-2 lg:p-3 hover:text-primary transition rounded-full shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
    >
        <i className="fas fa-phone-alt text-lg"></i>
    </a>
</div>
         
    </>

        
    );
};

export default PhoneIconTwo;
