import React, { useContext, useState, useEffect } from "react";
import Fab from '@mui/material/Fab';
import { UserContext } from '../../contexts/UserContext';

import { useJobId } from '../../contexts/ContextJbo';
const phoneIcon = () => {
    const { featureCustomizations } = useContext(UserContext);
    const [phoneNo, setPhoneNo] = useState('');
   
    const { jboId } = useJobId();
    useEffect(() => {
        if (featureCustomizations.mobile_number) {
            setPhoneNo(featureCustomizations.mobile_number);
        }
    }, [featureCustomizations]);

    // Render the UI only if the phone number exists
    if (jboId === 369 || !phoneNo) {
        return null;
    }

    return (
        <>
            {
                // phone icon postion updated
               
                <div class="fixed bottom-[50px] lg:bottom-[20px] right-[10px] z-[9999] w-12 h-12 bg-blue-500 text-white rounded-full flex items-center justify-center">
    <a
        href={`tel:${phoneNo}`}
        className="p-2 lg:p-3 hover:text-primary transition rounded-full shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
    >
        <i className="fas fa-phone-alt text-lg"></i>
    </a>
</div>
                
            }
        </>
    );
};

export default phoneIcon;
