import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MainNavBar from "./MainNavBar";
import MobileMenu from "./MobileMenu";
import { useRouter } from "next/router";

const Layout = ({ children }) => {
  const router = useRouter();
  console.log("router : ", router.pathname);

  return (
    
    <div className="flex flex-col min-h-screen">
         {/* Performance and Layout shift updated - 03-09-2024 */}
      {/* Header Section */}
  
        {/* <Header /> */}
        <MainNavBar />
   

      {/* Main Content */}
      <main className="flex-grow">
        {router.pathname === "/" ? (
          <div className="min-h-[1000px]">{children}</div>
        ) : (
          <div>{children}</div>
        )}
      </main>

      {/* Footer Section */}
      <footer className="bg-secondary pt-8 pb-12 lg:pb-0 border-t border-gray-100">
        <Footer />
      </footer>

      {/* Mobile Menu */}
      <MobileMenu />
    </div>
  );
};

export default Layout;
